@import "src/bulma"

@media (prefers-color-scheme: light)
  .searchbar
    background-color: $turquoise


@media (prefers-color-scheme: dark)
  .searchbar
    background-color: #000
